<template>
  <div ref="homme" class="home">
    <van-pull-refresh v-model="isLoading" :loading-text="loadingText" :pulling-text="pullingText"
      :loosing-text="loosingText" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" :finished-text="bomTitle" :offset="20"
        class="main" loading-text="Loading..." :immediate-check="false" @load="onLoad">
        <div v-for="(item, index) in list" :key="index" class="newsList">
          <div class="newsList_center" @click="goLanding(item.id)">
            <div class="word">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="newsInfo">
                <div class="channel">
                  {{ item.channel }}
                </div>
                <div class="round" />
                <div class="time">
                  {{ item.published }}
                </div>
              </div>
            </div>
            <div class="picurl">
              <img v-lazy="item.headImg?.[0]" alt="">
            </div>
          </div>

          <div v-if="index === 2" class="adsItem">
            <ins ref="ads1st" class="adsbygoogle" :data-ad-client="adsense.client"
              :data-ad-slot="adsense.slot1" data-ad-format="auto"
              data-full-width-responsive="true" />
          </div>

          <div v-if="index === 5" class="adsItem">
            <ins ref="ads2nd" class="adsbygoogle" :data-ad-client="adsense.client"
              :data-ad-slot="adsense.slot2" data-ad-format="auto"
              data-full-width-responsive="true" />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <div v-if="!requirDataAlready" class="loading">
      <img src="@/assets/news/loading.png" alt="">
    </div>
  </div>
</template>
<script>
import '@/css/news/home.scss';
export default {
  name: 'HomeCobine',
  data () {
    return {
      list: [],
      hasNext: false,
      isLoading: false, // 控制下拉刷新的加载状态
      loading: false, // 控制上拉加载的加载状态
      finished: false, // 是否已加载完全部数据
      bomTitle: '-- END --', // 上拉加载完成时的文本
      loadingText: 'Under loading...', // 加载过程提示文案
      pullingText: 'Pull down to refresh...', //下拉过程提示文案
      loosingText: 'Release to refresh...', // 释放过程提示文案
      page: 1,
      isinitialization: false,
      isData: false,
      requirDataAlready: true,
      // adsense: null,
      firstAdVisible: false,
      secondAdVisible: false,

      adStatusObserver: null,
      adDomObserver: null,
      adIntersectionObserver: null,
      iframeVisibilityTracker: null,
    };
  },
  computed: {
    host () {
      return this.$store.state.host;
    },
    adsense () {
      return this.$store.state.adsenseConfig;
    },
  },
  async mounted () {
    console.log('-------------------mounted');
    try {
      this.finished = false;
      this.loading = true;
      this.page = 1;
      await this.getList();
      this.initPage();
      this.setupIframeTracking();
      this.loadAdSenseScript();  // 重新加载广告脚本
    } catch (error) {
      console.error('An error occurred in getList:', error);
      // 可以在这里添加错误处理逻辑
    }
  },
  beforeDestroy () {
    console.log('-------------------beforeDestroy');
    this.removeAdScript();
    this.disconnectObservers();  // 新增方法调用以断开所有观察者
  },
  methods: {
    initPage () {
      this.$eventrackFb('show_page_news', 'expose', {
        pve_cur: '/h5page/home/',
      });

      window.onload = this.handleWindowLoad;
    },
    removeAdScript () {
      // 移除广告实例
      const ads = document.querySelectorAll('.adsbygoogle');
      ads.forEach(ad => {
        ad.remove();  // 如果是简单的广告，直接移除可能就足够了
      });

      // 重置 window.adsbygoogle 数组
      window.adsbygoogle = [];

      // 移除广告脚本标签
      const adScript = document.querySelector('script[src="' + this.adsense?.scriptUrl + '"]');
      if (adScript) {
        adScript.remove();
      }

    },
    disconnectObservers () {
      // 断开广告状态监视器
      if (this.adStatusObserver) {
        this.adStatusObserver.disconnect();
      }

      // 断开广告DOM监视器
      if (this.adDomObserver) {
        this.adDomObserver.disconnect();
      }

      // 断开广告可视状态监视器
      if (this.adIntersectionObserver) {
        this.adIntersectionObserver.disconnect();
      }

      // 可能还需要断开其他由 setupIframeTracking 创建的监听器
      if (this.iframeVisibilityTracker) {
        document.removeEventListener('visibilitychange', this.iframeVisibilityTracker);
      }
    },
    handleWindowLoad () {
      console.log('Window loaded');
    },
    setupIframeTracking () {
      // let _this = this;
      const url = window.location.href
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');
          if (adContainer) {
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
            this.$logger('ad_iframe_click')
            this.$receiveMsg(url)

          }
        });
      });
    },
    ttTrack (id, type = 'expose', name = 'ad') {
      if (this.host != 'gizmodomno.icu' && this.host != 'techcrunchabc.homes') return;
      console.log('track');
      ttq.track('ClickButton', {
        value: '100', // number. Value of the order or items sold. Example: 100.
        currency: 'USD', // string. The 4217 currency code. Example: "USD".
        contents: [
          {
            content_id: id, // string. ID of the product. Example: "1077218".
            content_type: type, // string. Either product or product_group.
            content_name: name, // string. The name of the page or product. Example: "shirt".
          },
        ],
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
              this.$logger('ad_iframe_click_leave')
              this.vues.ttTrack(this.activeIframe.adSlot, 'click', 'ad_iframe_click_leave');
            }
          }
        };

        // document.addEventListener(visibilityChangeEvent, onVisibilityChange);
        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrackFb(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        return; // 如果已加载，直接返回
      }
      this.loadScriptConditionally();
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
        setTimeout(() => {
          this.setupIframeTracking();
        }, 500);
      }, 1500);
    },
    loadScriptConditionally () {
      // 判断广告脚本URL是否存在
      if (!this.adsense?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsense.scriptUrl}"]`);
      if (existingScript) {
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsense?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

      this.setupAdObserver();
    },
    displayAd () {
      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      (window.adsbygoogle = window.adsbygoogle || []).push({});
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      this.$nextTick(() => {
        this.observeAdStatus();
      });
    },
    observeAdStatus () {
      let _this = this;
      const firstAdElement = this.$refs.ads1st[0];
      const secondAdElement = this.$refs.ads2nd[0];
      const observerCallback = (mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
            const target = mutation.target;
            const status = target.getAttribute('data-ad-status');
            if (target === firstAdElement) {
              this.firstAdVisible = status === 'filled';
            } else if (target === secondAdElement) {
              this.secondAdVisible = status === 'filled';
            }
            this.adIntersectionObserver.observe(target);
          }
        });
      };

      this.adStatusObserver = new MutationObserver(observerCallback);

      this.adIntersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // 检查是否已经处理过该广告
            if (entry.isIntersecting && !entry.target.dataset.viewed) {
              console.log('Ad viewed', entry.target);
              // 标记广告为已处理
              entry.target.dataset.viewed = 'true';
              console.log(entry.target.dataset.adSlot);
              _this.$eventrackFb('ad_fill_viewed', 'expose', {
                'data-ad-slot': entry.target.dataset.adSlot,
              });
              _this.$logger('ad_fill_viewed')

              // _this.ttTrack(entry.target.dataset.adSlot, 'expose', 'ad_fill_viewed');

              // 取消观察当前元素
              this.adIntersectionObserver.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 } // 至少50%的广告内容在视口中时触发
      );

      this.adStatusObserver.observe(firstAdElement, {
        attributes: true,
      });
      this.adStatusObserver.observe(secondAdElement, {
        attributes: true,
      });
    },
    setupAdObserver () {
      let _this = this;
      console.log('监听广告的加载状态');
      //设置 MutationObserver 监听特定元素
      const observerCallback = (mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            // 检查是否是广告相关的 DOM 变更
            if (mutation.target.matches('ins.adsbygoogle')) {
              console.log("AdSense ad loaded");
              // console.log(mutation.target); // console.log(mutation.target.dataset.adSlot);
              _this.$eventrackFb('ad_load_success', 'load', {
                'data-ad-slot': mutation.target.dataset.adSlot,
              });
              _this.$logger('ad_load_success')
            }
          }
        });
      }

      // 观察器的配置（观察子节点变动）
      const config = { childList: true, subtree: true };

      this.adDomObserver = new MutationObserver(observerCallback);
      // 开始观察当前组件根元素下的所有变化
      this.$refs.homme && this.adDomObserver.observe(this.$refs.homme, config);
    },

    async getList () {
      console.log(`this.host-->${this.host}`);
      // 获取当前 URL
      let url = window.location.href;
      // 移除 http:// 或 https:// 前缀
      let formattedUrl = url.replace(/^https?:\/\//, '');
      // 去掉路径和查询参数，只保留域名部分
      formattedUrl = formattedUrl.split('/')[0];

      try {
        const res = await this.$global.server.getList({
          pageNum: this.page,
          pageSize: 30,
          domain: this.host || formattedUrl,
        });
        console.log(res);
        this.page += 1;
        this.loading = false;
        this.isLoading = false;
        this.finished = !res.data.hasNext;

        if (res.code === 200) {
          let data = res.data.articleSummaryList;
          this.list.push(...data);
          if (this.list.length > 0) {
            this.isData = true;
          }
          this.hasNext = res.data.hasNext;
        } else {
          this.requirDataAlready = true;
        }

        if (!this.isinitialization) {
          this.isinitialization = true;
          this.$eventrackFb('news_list_init', 'expose', {
            pve_cur: '/h5page/news/x/',
            state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0,
          });
        }
      } catch (err) {
        console.log(err);
        this.requirDataAlready = true;
        this.isinitialization = true;
        this.finished = true;
        this.$eventrackFb('news_list_init_error', 'expose', {
          pve_cur: '/h5page/news/x/',
          state: -1,
        });
      }
    },
    goLanding (id) {
      this.$eventrackFb('news_goto_detail', 'click', {
        id: id,
      });
      this.$router.push({ name: 'landing', query: { id: id } });
    },
    onRefresh () {
      this.finished = false;
      this.loading = true;
      this.page = 1;
      this.onLoad();
    },
    onLoad () {
      this.getList();
      console.log('onLoad');
    },
  },
};
</script>

<template>
  <section>
    <homeCompon :class="`detect_deviece_${deviceType} state_${deviceType}`" />
  </section>
</template>

<script>
import homeCompon from '@/pages/combine/home.vue'
import '@/css/main.scss'

export default {
  name: 'HomeMain',
  components: {
    homeCompon,
  },
  computed: {
    deviceType () {
      return this.$store.state.deviceType;
    },
  },
}
</script>
